<template>
  <!-- <loading v-if="loading" /> -->
  <v-container class="pt-0">
    <template v-if="items && items.length">
      <back-toolbar :title="title"></back-toolbar>
      <item-list
        :items="items"
        :showSegmentedControl="showSegmentedControl"
        :rendered="rendered"
        @switchedSegment="rendered = 25"
      ></item-list>
    </template>

    <error-image
      v-else
      :errorDialog="true"
      :text="$t('dashboard.noRecommendation')"
      imageName="norecommendation.svg"
    ></error-image>

    <!-- <loading v-if="loadingMore"></loading> -->

    <!-- Scroll to top button -->
    <v-btn
      class="gradient scroll-button px-0"
      v-show="scY > 300"
      @click="toTop"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#fff"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="arcs"
      >
        <path d="M18 15l-6-6-6 6" />
      </svg>
    </v-btn>
  </v-container>
</template>

<script>
import ItemList from "@/components/recommendations/ItemList";
import BackToolbar from "@/components/app/common/BackToolbar";
import ErrorImage from "@/components/app/common/ErrorImage";
import findIndex from "lodash/findIndex";
import { mapState, mapGetters } from "vuex";
export default {
  components: {
    BackToolbar,
    ItemList,
    ErrorImage,
  },
  data() {
    return {
      scTimer: 0,
      scY: 0,
      // getMore: false,
      // advertPlacement: Math.floor(Math.random() * 6),
      // allowedRetries: 3,
    };
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.onScroll);
    });
    if (this.$store.state.session.pageYOffset) {
      window.scrollTo({
        top: this.$store.state.session.pageYOffset,
      });
    }
  },
  beforeDestroy() {
    // this.radius = 20;
    window.removeEventListener("scroll", this.onScroll);
    if (this.$router.past[this.$router.past.length - 1].name !== "detail") {
      this.$store.commit("session/SET_PAGEYOFFSET", undefined);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "detail") this.rendered = 25;
    next();
  },
  methods: {
    onScroll() {
      if (
        window.pageYOffset === 0 &&
        this.$store.state.session.pageYOffset > 30
      ) {
        window.scrollTo({
          top: this.$store.state.session.pageYOffset,
        });
        return;
      }
      this.$store.commit("session/SET_PAGEYOFFSET", window.pageYOffset);
      // Load more if close to the bottom and not loading
      if (
        this.items.length &&
        window.pageYOffset + window.innerHeight >=
          document.body.scrollHeight - 20
      ) {
        this.rendered += 25;
      }

      // update fields for scroll to top button
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  computed: {
    ...mapState({
      recommendations: ({ recommendations }) => recommendations,
      isMiKY: (state) => state.isMiKY,
      justforyouByMiKY: ({ partners }) =>
        partners.config?.filter_settings?.recommendations,
      likes: ({ profile }) => profile.data?.likes,
    }),
    ...mapGetters({
      dashboardRecommendations: "recommendations/dashboardRecommendations",
    }),
    rendered: {
      get() {
        return this.$store.state.session.rendered;
      },
      set(val) {
        this.$store.commit("session/SET_RENDERED", val);
      },
    },
    type() {
      return this.$router.currentRoute.query.type;
    },
    typeDetail() {
      return this.type === "byTag" &&
        !Array.isArray(this.$router.currentRoute.query.typeDetail)
        ? [this.$router.currentRoute.query.typeDetail]
        : this.$router.currentRoute.query.typeDetail;
    },
    internalType() {
      return this.type === "byTag" ? "tags" : "";
    },
    showSegmentedControl() {
      return this.type !== "byTag";
    },
    title() {
      if (this.typeDetail) {
        return `dashboard.${this.typeDetail}`; // Applies to "ByTag" only.
      } else {
        return `dashboard.${this.type}`;
      }
    },
    items() {
      let items = this.dashboardRecommendations;
      switch (this.type) {
        case "justForYou":
          if (this.isMiKY) return this.justforyouByMiKY;
          const categories = !this.likes?.length
            ? [{ title: "Dining" }]
            : this.likes;
          items = items.filter((item) =>
            categories.find((c) => c.title === item.category?.title)
          );
          return items;
        case "attractions":
          return items.filter((item) => item.category?.title === "Attractions");
        case "highlights":
          return items.filter((item) => item.highlight);
        case "popular":
          return items.filter((item) => item.clicked > 0);
        case "recommendations":
          return items;
        case "byTag":
          let index = findIndex(this.recommendations[this.type + "V4"], [
            this.internalType,
            this.typeDetail,
          ]);
          console.log(index);
          return this.recommendations[this.type + "V4"][index].items;
        default:
          return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-button {
  border-radius: 10px;
  position: fixed;
  bottom: calc(70px + env(safe-area-inset-bottom));
  right: 20px;
  min-width: 36px !important;
  max-width: 36px !important;
  cursor: pointer;
}
</style>
